/* eslint-disable prefer-promise-reject-errors */
import { EditOutlined, SendOutlined } from '@ant-design/icons'
import {
  Button, Drawer, Dropdown, Form, Image, Input, InputNumber, message, Modal, Tabs,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useRef, useState } from 'react'
import ExternalAccountApis from '../../../apis/externalAccountApis'
import { handlePermission, isHasPermission } from '../../../redux/reducers/permission'
import { PERMISSION, ROLE } from '../../../constants/permission'
import { getProfile, resetPassword } from '../../../apis/authenticationApis'
import { formatNumber } from '../../../util'
import BonusPointHistory from '../../manageBonusPoint/BonusPointHistory'
import BonusPointApis from '../../../apis/bonusPointApis'
import ExternalAccountFormWithDisplayName from './ExternalAccountFormWithDisplayName'
import CreateExternalAccountFormWithDisplayName from './CreateExternalAccountFormWithDisplayName'
import ExternalAccountForm from './ExternalAccountForm'

const TEXT = {
  CONFIRM_RESET_PASSWORD_TITLE: 'Bạn muốn thiết lập lại mật khẩu?',
  RESET_PASSWORD_SUCCESS_TITLE: 'Mật khẩu đã thiết lập lại thành công',
  CONFIRM_RESET_PASSWORD_CONTENT:
    '<p>Mật khẩu của tài khoản được chọn sẽ được thiết lập lại về mật khẩu mặc định</p>',
  RESET_PASSWORD_SUCCESS_CONTENT:
    '<div>Tên tài khoản: <b>{{username}}</b></div><div>Mật khẩu: <b>Password@123</b></div>',
}

function ExternalAccountDrawer(props) {
  const {
    isOpenDrawer,
    setIsOpenDrawer,
    getListExternalAccounts,
    detailID,
    getDetailAccountFunction,
    isProfile,
    isKHDN,
  } = props
  const [detailData, setDetailData] = useState(null)
  const [modalName, setModalName] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [note, setNote] = useState('')
  const [reason, setReason] = useState('')
  const [isOpenConfirmModalResetPassword, setIsOpenConfirmModalResetPassword] = useState(false)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [rewardPointData, setRewardPointData] = useState(0)
  const [inputPoint, setInputPoint] = useState(0)
  const [isShowModalReward, setIsShowModalReward] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [totalHistory, setTotalHistory] = useState(0)
  const [loadingHistory, setLoadingHistory] = useState(false)
  const [updateRewardPointForm] = Form.useForm()
  const [isShowModalConfirmReward, setIsShowModalConfirmReward] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState('1')

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    order_by: 'created_at',
    sort_by: 'desc',
    transaction_types: [
      'edit_point',
      'admin_add_point',
      'admin_subtract_point',
      'add_point',
      'EXCHANGE_POINT_REQUEST_ACTIVE_SIM',
      'REFUND_POINT_REQUEST_ACTIVE_SIM',
    ],
  })

  const formExternalAccountRef = useRef(null)

  const handleOpenModal = (modal_name) => {
    setModalName(modal_name)
    setIsOpenModal(true)
  }

  const handleCloseDrawerCreate = () => {
    setIsOpenDrawer(false)
    setIsEdit(false)
  }

  const getModalTitle = () => {
    if (modalName === 'active') {
      return 'Bạn muốn kích hoạt tài khoản?'
    }

    if (modalName === 'suspend') {
      return 'Bạn muốn vô hiệu hoá tài khoản?'
    }

    if (modalName === 'approve') {
      return 'Duyệt tài khoản?'
    }

    if (modalName === 'reject') {
      return 'Từ chối tài khoản?'
    }

    return ''
  }

  const getModalContent = () => {
    if (modalName === 'active') {
      return <p>Bạn có chắc chắn muốn kích hoạt tài khoản này?</p>
    }

    if (modalName === 'suspend') {
      return (
        <div className="reject-info">
          <div className="modal-description">
            Bạn có chắc chắn muốn vô hiệu hoá tài khoản này?
          </div>
          <TextArea
            rows={2}
            placeholder="Nhập lý do từ chối"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="modal-reason"
          />
        </div>
      )
    }

    if (modalName === 'approve') {
      return <p>Bạn có chắc chắn muốn duyệt tài khoản này?</p>
    }

    if (modalName === 'reject') {
      return (
        <>
          <p>Bạn có chắc chắn muốn từ chối tài khoản này?</p>
          <Input.TextArea
            placeholder="Nhập lý do từ chối"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </>
      )
    }

    return ''
  }

  const getOkButtonClass = () => {
    if (modalName === 'active' || modalName === 'approve') {
      return 'btn-green'
    }

    if (modalName === 'suspend' || modalName === 'reject') {
      return 'btn-red'
    }

    return ''
  }

  const getOkTextModal = () => {
    if (modalName === 'active') {
      return 'Kích hoạt'
    }
    if (modalName === 'suspend') {
      return 'Xác nhận'
    }
    if (modalName === 'approve') {
      return 'Duyệt'
    }
    if (modalName === 'reject') {
      return 'Từ chối'
    }
    return ''
  }

  const handleOkModal = async (modal_name) => {
    try {
      if (modal_name === 'active') {
        await ExternalAccountApis.activeExternalAccount(detailID)
        message.success('Kích hoạt tài khoản thành công')
      } else if (modal_name === 'suspend') {
        await ExternalAccountApis.suspendExternalAccount(detailID, { note })
        setNote('')
        message.success('Vô hiệu hoá tài khoản thành công')
      } else if (modal_name === 'approve') {
        await ExternalAccountApis.approveExternalAccount(detailID)
        message.success('Duyệt tài khoản thành công')
      } else if (modal_name === 'reject') {
        await ExternalAccountApis.rejectExternalAccount(detailID, {
          note: reason,
        })
        setReason('')
        message.success('Từ chối tài khoản thành công')
      }
      setIsOpenModal(false)
      getListExternalAccounts()
      handleCloseDrawerCreate()
    } catch (error) {
      message.error('Đã có lỗi xảy ra')
    }
  }

  const getDetailExternalAccount = async (id) => {
    setLoading(true)
    try {
      const detailRawData = await getDetailAccountFunction()
      setDetailData(detailRawData)
      setRewardPointData(Number(detailRawData?.commission_point))
      // formExternalAccountRef.current.patchDataToForm(detailData)
    } catch (error) {
      message.error('Đã có lỗi khi lấy dữ liệu')
      handleCloseDrawerCreate()
    } finally {
      setLoading(false)
    }
  }

  const getHistoryWallet = async () => {
    setLoadingHistory(true)
    try {
      const [profileRs, apiRs] = await Promise.all([
        getProfile(),
        BonusPointApis.getMyHistoryWallet({
          ...queryParams,
        }),
      ])
      setRewardPointData(Number(profileRs?.data?.data?.commission_point))
      setHistoryList(apiRs?.data?.data?.items || [])
      setTotalHistory(apiRs?.data?.data?.meta?.total)
    } catch (error) {
      console.log('error', error)
    }
    setLoadingHistory(false)
  }

  useEffect(() => {
    if (activeTabKey === '2') {
      getHistoryWallet()
    }
  }, [queryParams, activeTabKey])

  const submitForm = () => {
    formExternalAccountRef.current.createForm.submit()
  }

  const EditButton = () => (
    <Button
      icon={<EditOutlined />}
      size="large"
      onClick={() => {
        setIsEdit(true)
      }}
    >
      Chỉnh sửa
    </Button>
  )

  const EditingBlock = () => (
    <div className="wrap-btn">
      <Button
        onClick={() => {
          setIsEdit(false)
          setDetailData((prevData) => ({ ...prevData }))
        }}
      >
        Hủy
      </Button>
      <Button
        type="primary"
        icon={<SendOutlined />}
        size="large"
        onClick={() => {
          submitForm()
        }}
        disabled={isSaving}
      >
        Lưu
      </Button>
    </div>
  )

  const handleResetPassword = () => {
    setIsOpenConfirmModalResetPassword(true)
  }

  const handleSubmit = async () => {
    setSaving(true)
    const user = detailData

    try {
      const data = await resetPassword({
        username: `${user?.username}`,
      })

      if (data?.data?.data) {
        Modal.success({
          title: (
            <div
              style={{ fontSize: 20 }}
              dangerouslySetInnerHTML={{
                __html: TEXT.RESET_PASSWORD_SUCCESS_TITLE,
              }}
            />
          ),
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html: TEXT.RESET_PASSWORD_SUCCESS_CONTENT.replace(
                  '{{username}}',
                  user?.username,
                ),
              }}
            />
          ),
          width: 456,
          icon: (
            <img
              src="/images/common/check_circle.svg"
              alt="check_circle"
              width={48}
              height={48}
              style={{ marginRight: 16 }}
            />
          ),
          footer: (_, { OkBtn, CancelBtn }) => (
            <div style={{ textAlign: 'right' }}>
              <Button
                key="submit"
                className="btn-confirm"
                onClick={() => Modal.destroyAll()}
              >
                Ok
              </Button>
            </div>
          ),
        })
      } else {
        message.error('Không tìm thấy thông tin tài khoản')
      }
    } catch (e) {
      message.error('Không tìm thấy thông tin tài khoản')
    } finally {
      setIsOpenConfirmModalResetPassword(false)
      setSaving(false)
    }
  }

  const handleCancel = () => {
    setIsOpenConfirmModalResetPassword(false)
  }

  const items = [
    {
      label: (
        <div
          className="btn-dropdown"
          role="button"
          tabIndex={0}
          onClick={() => {
            setIsEdit(true)
          }}
        >
          <img
            width={24}
            height={24}
            alt="edit"
            src="/images/common/edit.svg"
          />
          Sửa thông tin
        </div>
      ),
      key: '0',
      disabled: false,
    },
    {
      label: (
        <div
          className="btn-dropdown"
          role="presentation"
          onClick={handleResetPassword}
        >
          <img
            width={24}
            height={24}
            alt="reset"
            src="/images/common/reset.svg"
          />
          Thiết lập lại mật khẩu
        </div>
      ),
      key: '1',
    },
    isHasPermission(PERMISSION.DELETE_ACCOUNT) && {
      label: (
        <div
          className="btn-dropdown text-red"
          role="presentation"
          onClick={() => {
            setIsOpenModalDelete(true)
          }}
        >
          <img
            width={24}
            height={24}
            alt="edit"
            src="/images/common/recycle_red_fill.svg"
          />
          Xóa tài khoản
        </div>
      ),
      key: '2',
    },
  ]

  const handleDeleteAccount = async () => {
    try {
      await ExternalAccountApis.deleteAccount(detailID)
      setIsOpenModalDelete(false)
      setIsOpenDrawer(false)
      getListExternalAccounts()
      message.success('Xóa tài khoản thành công')
    } catch {
      message.error('Xóa tài khoản thất bại')
    }
  }

  const tabBarExtraContent = () => {
    if (detailData?.status === 'suspend') {
      return (
        <Button
          size="large"
          className="extra-btn btn-green"
          onClick={() => handleOpenModal('active')}
        >
          Kích hoạt
        </Button>
      )
    }

    if (detailData?.status === 'active') {
      return (
        <div className="wrap-btn">
          {isEdit ? (
            <EditingBlock />
          ) : (
            isHasPermission(
              PERMISSION.EDIT_DETAIL_ACCOUNT,
              handlePermission(),
            ) && (
              <>
                {/* <EditButton /> */}
                <Button
                  size="large"
                  className="extra-btn btn-red"
                  onClick={() => handleOpenModal('suspend')}
                >
                  Vô hiệu hoá
                </Button>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={['click']}
                  overlayClassName="sim-dropdown"
                >
                  <img
                    alt="show-more"
                    width={40}
                    height={40}
                    src="/images/common/show-more.svg"
                  />
                </Dropdown>
              </>
            )
          )}
        </div>
      )
    }

    if (detailData?.status === 'pending') {
      return (
        <div className="wrap-btn">
          {isEdit ? (
            <EditingBlock />
          ) : (
            <>
              <EditButton />
              <Button
                icon={<SendOutlined />}
                size="large"
                onClick={() => handleOpenModal('reject')}
              >
                Từ chối
              </Button>
              <Button onClick={() => handleOpenModal('approve')}>Duyệt</Button>
            </>
          )}
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (detailID) {
      getDetailExternalAccount(detailID)
    }
  }, [detailID])

  const [externalRoles, setExternalRoles] = useState([])
  const [sellers, setListSellers] = useState([])

  const getListExternalRoles = async () => {
    const res = await ExternalAccountApis.getExternalRoles()
    let rs = res?.data?.data || []
    if (isKHDN) {
      rs = rs.filter((x) => x.name === 'Khách hàng doanh nghiệp')
    } else {
      rs = rs.filter((x) => x.name !== 'Khách hàng doanh nghiệp')
    }
    setExternalRoles(rs)
  }

  const getListSellers = async () => {
    const res = await ExternalAccountApis.getSellers({
      status: 'active',
    })
    const listSellers = res?.data?.data?.items
    setListSellers(listSellers)
  }

  useEffect(() => {
    getListExternalRoles()
  }, [])

  useEffect(() => {
    getListSellers()
  }, [])

  const closeUpdateRewardPoint = () => {
    setInputPoint(0)
    setIsShowModalReward(false)
    updateRewardPointForm.resetFields()
  }

  const handleUpdateRewardPoint = () => {
    if (inputPoint <= 0) {
      message.error('Số điểm thưởng đổi phải lớn hơn 0')
      return
    } if (inputPoint > rewardPointData) {
      message.error('Số điểm thưởng đổi không được lớn hơn số điểm đang có!')
      return
    }
    updateRewardPointForm.submit()
  }

  const updateRewardPoint = async () => {
    setIsShowModalConfirmReward(true)
  }

  const handleConfirmReward = async () => {
    try {
      const apiRs = await BonusPointApis.exchangeRequest({
        point: inputPoint,
      })
      message.success('Tạo yêu cầu đổi thành công')
      getHistoryWallet()
    } catch {
      message.error('Tạo yêu cầu đổi thất bại')
    }
    setIsShowModalReward(false)
    setIsShowModalConfirmReward(false)
  }

  useEffect(() => {
    if (!isShowModalReward) {
      updateRewardPointForm.resetFields()
      setInputPoint(0)
    }
  }, [isShowModalReward])

  const handleTabChange = (key) => {
    setActiveTabKey(key)
  }

  return (
    <>
      <Drawer
        width={900}
        open={isOpenDrawer}
        title={
          detailID
            ? isEdit
              ? 'Chỉnh sửa tài khoản'
              : 'Chi tiết tài khoản'
            : 'Tạo tài khoản mới'
        }
        className="drawer-common create-external-account"
        onClose={handleCloseDrawerCreate}
        extra={
          !detailID && (
            <div className="drawer-extra">
              <Button onClick={handleCloseDrawerCreate}>Hủy</Button>
              <Button
                type="primary"
                icon={<SendOutlined />}
                size="large"
                onClick={() => {
                  submitForm()
                }}
                disabled={isSaving}
              >
                Lưu
              </Button>
            </div>
          )
        }
      >
        {detailID ? (
          <Tabs onChange={handleTabChange} tabBarExtraContent={!isProfile ? tabBarExtraContent() : null}>
            <Tabs.TabPane tab="Tổng quan" key="1">
              {(detailData?.roles?.[0]?.role_tag === ROLE.AGENT_EXTERNAL_ACCOUNT || detailData?.roles?.[0]?.role_tag === ROLE.COLLABORATOR_EXTERNAL_ACCOUNT) ? (
                <ExternalAccountFormWithDisplayName
                  ref={formExternalAccountRef}
                  getListExternalAccounts={getListExternalAccounts}
                  detailID={detailID}
                  isLoading={isLoading}
                  detailData={detailData}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  setSaving={setSaving}
                  handleCloseDrawer={handleCloseDrawerCreate}
                  externalRoles={externalRoles}
                  sellers={sellers}
                  isOpenDrawer={isOpenDrawer}
                  isProfile={isProfile}
                />
              ) : (
                <ExternalAccountForm
                  ref={formExternalAccountRef}
                  getListExternalAccounts={getListExternalAccounts}
                  detailID={detailID}
                  isLoading={isLoading}
                  detailData={detailData}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  setSaving={setSaving}
                  handleCloseDrawer={handleCloseDrawerCreate}
                  externalRoles={externalRoles}
                  sellers={sellers}
                  isOpenDrawer={isOpenDrawer}
                  isProfile={isProfile}
                />
              )}

            </Tabs.TabPane>
            {isProfile && isOpenDrawer && (
              <Tabs.TabPane tab="Ví tích điểm" key="2">
                <div className="bonus-point-info" style={{ marginBottom: 24 }}>
                  <div className="bonus-point-text">
                    <div className="wallet-icon-wrapper star-icon">
                      <Image
                        className="wallet-icon"
                        src="/images/common/stars.svg"
                        preview={false}
                      />
                    </div>

                    <div className="total-point">
                      <div className="total-point-title">
                        Tổng điểm tích luỹ
                      </div>
                      <div className="total-point-value">
                        {formatNumber(rewardPointData)}
                      </div>
                    </div>
                  </div>
                  <Button
                    disabled={false}
                    className="btn-green"
                    onClick={() => setIsShowModalReward(true)}
                  >
                    Đổi điểm
                  </Button>
                </div>
                <BonusPointHistory
                  data={historyList}
                  setQueryParams={setQueryParams}
                  loadingHistory={loadingHistory}
                  setData={setHistoryList}
                  totalCount={totalHistory}
                  page={queryParams.page}
                />
                <Modal
                  open={isShowModalReward}
                  title="Đổi điểm thưởng"
                  onOk={handleUpdateRewardPoint}
                  onCancel={closeUpdateRewardPoint}
                  cancelButtonProps={{
                    size: 'large',
                  }}
                  okButtonProps={{
                    className: 'btn-green',
                  }}
                  cancelText="Hủy"
                  okText="Xác nhận"
                  centered
                  width={776}
                >
                  <div
                    className="update-reward-point-modal"
                    style={{ marginTop: 40 }}
                  >
                    <Form
                      form={updateRewardPointForm}
                      layout="vertical"
                      className="input-right"
                      onFinish={updateRewardPoint}
                    >
                      <Form.Item
                        label="Số điểm muốn đổi"
                        name="point"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số điểm!',
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          value={inputPoint}
                          onChange={(value) => {
                            setInputPoint(value)
                          }}
                        />
                      </Form.Item>
                    </Form>
                    <div className="point-edit-div">
                      <div className="point-edit-block">
                        <div className="key-text">Quy đổi thành tiền</div>
                        <div className="value-text">
                          {`VND ${formatNumber(inputPoint)}`}
                        </div>
                      </div>
                      <div className="point-edit-block">
                        <div className="key-text">Điểm thưởng còn lại</div>
                        <div className="value-text">
                          {formatNumber(rewardPointData - inputPoint)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  centered
                  open={isShowModalConfirmReward}
                  wrapClassName="modal-activate-sim modal-approve"
                  onCancel={() => setIsShowModalConfirmReward(false)}
                  onOk={handleConfirmReward}
                  okText="Duyệt"
                  cancelText="Hủy"
                  style={{ width: 456 }}
                >
                  <div className="modal-content">
                    <img src="/images/common/approve-fill.svg" alt="approve" />
                    <div>
                      <div className="modal-title">Đổi điểm thưởng?</div>
                      <div className="modal-description">
                        Bạn xác nhận muốn đổi số điểm
                        {' '}
                        <b>{inputPoint}</b>
                        {' '}
                        thành
                        số tiền VND
                        {' '}
                        <b>{formatNumber(inputPoint)}</b>
                        ?
                      </div>
                    </div>
                  </div>
                </Modal>
              </Tabs.TabPane>
            )}
          </Tabs>
        ) : (
          <>
            {isKHDN
              ? (
                <ExternalAccountForm
                  ref={formExternalAccountRef}
                  getListExternalAccounts={getListExternalAccounts}
                  handleCloseDrawer={handleCloseDrawerCreate}
                  setSaving={setSaving}
                  externalRoles={externalRoles}
                  sellers={sellers}
                  isOpenDrawer={isOpenDrawer}
                />
              )
              : (
                <CreateExternalAccountFormWithDisplayName
                  ref={formExternalAccountRef}
                  getListExternalAccounts={getListExternalAccounts}
                  handleCloseDrawer={handleCloseDrawerCreate}
                  setSaving={setSaving}
                  externalRoles={externalRoles}
                  sellers={sellers}
                  isOpenDrawer={isOpenDrawer}
                />
              )}
          </>
        )}
      </Drawer>

      <Modal
        className="modal-external-account"
        centered
        title={getModalTitle()}
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onOk={() => handleOkModal(modalName)}
        okButtonProps={{
          className: getOkButtonClass(),
        }}
        okText={getOkTextModal()}
        cancelText="Hủy"
      >
        {getModalContent()}
      </Modal>

      <Modal
        centered
        open={isOpenModalDelete}
        onCancel={() => setIsOpenModalDelete(false)}
        onOk={handleDeleteAccount}
        wrapClassName="modal-activate-sim modal-reject"
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          <div className="reject-info">
            <div className="modal-title">Xác nhận xoá tài khoản tài khoản?</div>
            <div className="modal-description">
              Tài khoản này sẽ không còn tồn tại trong hệ thống. Vui lòng xác
              nhận
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isOpenConfirmModalResetPassword}
        title={(
          <div
            style={{ fontSize: 20 }}
            dangerouslySetInnerHTML={{
              __html: TEXT.CONFIRM_RESET_PASSWORD_TITLE,
            }}
          />
        )}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button
            key="submit"
            className="btn-confirm"
            onClick={handleSubmit}
            disabled={isSaving}
            isLoading={isSaving}
          >
            Xác nhận
          </Button>,
        ]}
        width={456}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: TEXT.CONFIRM_RESET_PASSWORD_CONTENT,
          }}
        />
      </Modal>
    </>
  )
}

export default ExternalAccountDrawer
